import { useSearchParams } from 'react-router-dom'

import { SortDirectionToggle as BaseSortDirectionToggle } from '@fv/client-components'
import { parseQueryString, stringifyQueryString } from '@fv/client-core'

import { type SortDirection } from './hooks/useShipmentListParams'

type SortDirectionToggleProps = { sortDirection: SortDirection }

export const SortDirectionToggle = ({
  sortDirection,
}: SortDirectionToggleProps) => {
  const [, setSearchParams] = useSearchParams()

  return (
    <BaseSortDirectionToggle
      value={sortDirection}
      onChange={val => {
        setSearchParams(p =>
          stringifyQueryString({
            ...parseQueryString(p.toString()),
            sortDirection: val === '1' ? '-1' : '1',
          }),
        )
      }}
    />
  )
}
